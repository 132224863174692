import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class TransactionService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listTransactions (filters) {
		const response = await this.axios.get("transaction/listTransactions", { params: { filters } });
		return response.data;
	}

	async createTransaction (payload) {
		const response = await this.axios.post("transaction/createTransaction", payload);
		return response.data;
	}

	async updateTransaction (payload) {
		const response = await this.axios.patch("transaction/updateTransaction", payload);
		return response.data;
	}

	async deleteTransaction (payload) {
		const response = await this.axios.patch("transaction/deleteTransaction", payload);
		return response.data;
	}

	async getTransactionsByParams (filters) {
		const response = await this.axios.get("transaction/getTransactionsByParams", { params: { filters } });
		return response.data;
	}
}
